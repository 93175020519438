import React, { useEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import NotFound from "./NotFound";
import data from "../data/restData";
import RestGallery from "./RestGallery";
import Footer from "./Footer";
// ICONS
import backIcon from "../icons/back.svg";
import locationIcon from "../icons/location.svg";
import phoneIcon from "../icons/phone.svg";

import { useTranslation } from "react-i18next";

import { useGetTableData, TablesStatus } from './TableStatus';
import LangSelect from './LangSelect';

const RestPage = () => {
    const { rest } = useParams();
    const tableData = useGetTableData();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0)
        if(data[rest]){
            document.title = data[rest].title[i18n.language];
        }else{
            document.title = t('pagenotfound')+' | IGGI'
        }
    }, [rest])

    if(!data[rest]) return <NotFound />

    const socialIcons = {
        instagram: require('../icons/instagram.svg').default,
        facebook: require('../icons/facebook.svg').default
    }

    const typeNames = {
        food: t('menu'),
        drinks: t('drinkMenu'),
        cocktails: t('cocktailsMenu')
    }

    var pdfs = '';
    if(data[rest].pdf){
        pdfs = Object.values(data[rest].pdf).map((value, key) => {
            // CHECK IF FILE EXISTS
            if(!(i18n.language in value.file)) return null;
            
            const fileLoc = require('/public/files/'+value.file[i18n.language]);
            let name = null;

            if(value.type !== 'extra'){
                name = typeNames[value.type];
            }else{
                name = value.name
            }

            return(
                <a href={fileLoc} target="_blank" rel="noreferrer" key={key}>
                    <button className="restMenuBtn" alt={typeNames[value.type]} >
                        {name}
                    </button>
                </a> 
            )
        })
    }

    const socialIconsDiv = Object.keys(data[rest].social).map((key) => {
        const socialData = data[rest].social[key];
        return <div className="socialIcon" key={key}><img src={ socialIcons[key] } alt={key} /><span><a href={socialData.link} target="_blank" rel="noreferrer">{socialData.name}</a></span></div>
    })
    return(
        <>
        <div className="restHeader">
            <div className="headerContent">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Link to="/" className="backLink"><img src={ backIcon } alt="home"/> { t('home') }</Link>
                    <LangSelect dark />
                </div>
                <div className="contentText">
                    <div className="contentHeader">
                        <div className="contentName">
                            <h2>{ data[rest].name }</h2>
                            <span>{ data[rest].city }</span>
                        </div>
                        { data[rest].showStatus &&
                            <div className="tableStatusRestPage">
                                <TablesStatus rest={rest} data={tableData} />
                            </div>
                        }  
                    </div>
                    <div className="contacts">
                        <div>
                            <img src={ locationIcon } alt="Adrese" />
                            <p>{ data[rest].address }<br />{ data[rest].city }</p>
                        </div>
                        <div>
                            <img src={ phoneIcon } alt="Telefona numurs" />
                            <p>+371 { data[rest].phone }</p>
                        </div>
                    </div>
                    <p className="description">{ data[rest].description[i18n.language] }</p>
                </div>
                <div className="socials">
                    { socialIconsDiv }
                </div>
                <div className="pdfBtns">
                    { pdfs }
                </div>
            </div>
        </div>
        <RestGallery rest={ rest } images={ data[rest].images } />
        <Footer />
        </>
    );
}

export default RestPage;