import bus_4 from '../img/bus/bus_4.jpg';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";

export default function Bus() {
    const anchorRef = useRef(null);
    const { t } = useTranslation();

    useEffect(() => {
        if(window.location.href.includes('izbraukumi')){
            anchorRef.current.scrollIntoView({ behavior: 'smooth' });
        }

      }, []);

    return(
        <div className="container" style={{marginTop: '170px'}} id="bus" ref={anchorRef}>
            <div className="homepageContent homepageContentImg" style={{alignItems: 'flex-start'}}>
                <div className="homepageImg">
                    <img src={bus_4} alt="IGGI Izbraukumi" style={{objectPosition: 'bottom'}} />
                </div>
                <div className="homepageText">
                    <h2>{ t('bus.title') }</h2>
                    <p dangerouslySetInnerHTML={{ __html: t('bus.description') }} />
                </div>
            </div>
        </div>
    )
}